<template>
  <v-sheet class="pa-4">
    <league-player-card 
      v-for="player in league_players" 
      :key="player.id + '_player'"
      :player="player"
    >
    </league-player-card>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LeaguePlayerCard from '@/components/tiimi_admin/league/player/LeaguePlayerCard.vue';

export default {
  created() {
    this.initLeaguePlayers({ league_id: this.$route.params.league_id })
    if(!this.clubs.length) this.initClubs(this.$route.params.league_id)
  },
  components: {
    LeaguePlayerCard
  },
  methods: {
    ...mapActions('admin', [
      'initLeaguePlayers',
      'initClubs'
    ])
  },
  computed: {
    ...mapGetters('admin', [
      'league_players',
      'loading_league_players',
      'clubs'
    ])
  }
}
</script>