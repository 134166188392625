<template>
  <v-sheet style="border: 1px solid grey; border-radius: 5px;" class="d-flex justify-start align-center my-2 py-2">
    <v-sheet style="border-radius: 5px;">
      <v-img :src="player.picture_url" width="60" height="60" contain></v-img>
    </v-sheet>
    <v-sheet class="text-button" style="width: 250px;">
      {{ player.player_name }}
    </v-sheet>
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{on, attrs}">
        <v-btn
          class="mr-4"
          v-bind="attrs"
          v-on="on"
          fab
          text
        >
          <v-img width="35" height="35" contain :src="club.small_logo_url" />
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item-group>
          <v-list-item @click="update(club.id)" v-for="club in clubs" :key="club.id + '_club'">
            <v-list-item-icon>
              <v-img :src="club.small_logo_url" heigh="30" width="30" contain />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ club.club_name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: ['player'],
  created() {
  },
  methods: {
    ...mapActions('admin', [
      'updatePlayer'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    update(league_club_id) {
      this.updatePlayer({ id: this.player.id, league_club_id })
        .then(() => {
          this.success('updated player')
        })
        .catch(e => {
          this.error(e)
        })
    }
  },
  computed: {
    ...mapGetters('admin', [
      'league_club_by_id',
      'clubs'
    ]),
    club() {
      return this.league_club_by_id(this.player.league_club_id)
    }
  }
}
</script>